.list {
    line-height: 18px;
    font-size: 14px;
    max-width: 80px;
    min-width: 80px;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--background-color) !important;
    color: var(--text-color) !important;
    scrollbar-width: thin;
    &>div {
        padding: 0 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .ans-dot {
            background: #000;
            min-width: 3px;
            height: 3px;
            margin-right: 3px;
        }
        &.answered .ans-dot {
            background: transparent;
        }
        .correct-icon {
            color: #49f049;
        }
        .marked-icon {
            color: red;
            font-size: 13px;
            margin-left: 4px;
            vertical-align: middle;
        }
    }
    &>div:nth-child(odd) {
        background-color: var(--list-bkg);
    }
    .active {
        background-color: var(--list-item-sel-color) !important;
        color: #fff;
        .ans-dot {
            background: #fff;
        }
    }
    .ans-icon {
        font-size: 13px;
        vertical-align: middle;
        margin-right: 5px;
    }
}

.quiz-page .list {
    &>div .ans-dot {
        margin-right: 15px;
        margin-left: 5px;
    }
    &>div .marked-icon {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .list {
        .ans-icon,
        .ans-dot {
            margin-right: 8px;
        }
    }
}