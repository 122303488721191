.admin-portal {
    .header-title {
        flex: 1;
        text-align: center;
    }
    .body-container {
        padding: 20px;
        .coupon-create-btn {
            float: right;
        }
        .list-container {
            width: 40%;
            float: left;
            margin-top: 40px;
            .header {
                margin-bottom: 20px;
            }
            .list-item {
                display: flex;
                border-bottom: 1px solid green;
                padding: 15px;
                align-items: center;
                padding-left: 0;
                .coupon-dtls {
                    flex: 1;
                }
                .coupon-exp {
                    margin-top: 10px;
                    font-size: 13px;
                }
                .coupon-amt {
                    font-size: 25px;
                    font-weight: bold;
                    color: green;
                }
            }
        }
    }
}

.coupon-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-container {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        width: 40%;
        .header {
            text-align: center;
            margin-bottom: 10px;
        }
        .coupon-value {
            margin: 10px 0 5px;
            input {
                padding-top: 10px;
                color: #000;
                &:disabled {
                    background: #dcdcdc;
                    padding-left: 10px;
                }
            }
        }
        .discount-cont {
            margin: 20px 0;
            .type {
                margin-right: 15px;
                width: 40%;
            }
        }
        .expiry {
            display: block;
            margin-bottom: 20px;
        }
        .create-btn {
            float: right;
            margin-left: 10px;
        }
    }
}

.update-plan-modal,
.send-invites-modal {
    .MuiSelect-root {
        text-align: left;
    }
}

.admin-plan-container {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    .btns-cont {
        align-self: end;
    }
}

.marg-right {
    margin-right: 15px !important;
}

.plan-edit-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    &>div {
        margin-bottom: 10px;
    }
}

.update-plan-modal .modal-container,
.send-invites-modal .modal-container {
    margin: 2% auto !important;
}

.max-width-row {
    max-width: 200px;
}

.min-width-row {
    min-width: 70px;
    button {
        padding: 5px;
    }
}

.admin-refund-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.search-list-cont {
    margin: 40px 20px;
    max-width: 350px;
}
.search-inpt {
    width: 100%;
}

.plan-table {
    flex: 1;
    margin-top: 40px;
    margin-right: 20px;
    table {
        border: 1px solid #dcdcdc;
    }
}
.user-list-cont {
    margin-top: 20px;
    .user-list {
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        margin-top: 5px;
        >div {
            padding: 10px;
            border-bottom: 1px solid #dcdcdc;
        }
        .list-item {
            display: flex;
            justify-content: space-between;
        }
    }
    .list-label {
        color: #00a500;
    }
}
.row-center {
    text-align: center !important;
}

@media only screen and (max-width: 600px) {
    .coupon-modal .modal-container {
        width: 85%;
    }
    .update-plan-modal .modal-container {
        width: 75%;
        margin-top: 8% !important;
    }
    .admin-refund-container {
        flex-direction: column;
    }
}