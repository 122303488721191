$blue: #1842b4;
.quiz-percent-cont,
.left-sidebar,
.right-sidebar {
    border: 1px solid var(--border-color);
}

.overall-performance-header {
    flex: 1;
    .upper-header {
        display: flex;
        flex: 1;
        .header-sidebar-left {
            flex: 1;
            .title {
                color: $blue;
            }
            .sub-title {
                font-weight: normal;
                margin-top: 20px;
            }
        }
        .quiz-percent-cont {
            display: flex;
            box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
            padding: 15px;
            .report-details-wrapper {
                display: flex;
                .report-details {
                    display: flex;
                    justify-content: center;
                    margin-right: 10px;
                    flex-direction: column;
                    text-align: center;
                    color: var(--text-color) !important;
                    font-size: 14px;
                }
            }
            .quiz-progress-text-bold {
                font-weight: bold;
                padding-right: 4px;
                line-height: 25px;
                color: var(--text-color) !important;
                font-size: 20px;
            }
            .quiz-percent-progress {
                position: relative;
                height: 90px;
                margin-right: 10px;
                .circular-progress-bkg {
                    color: #e1e0e0;
                    position: absolute;
                }
                .MuiCircularProgress-root {
                    width: 95px !important;
                    height: 95px !important;
                    .MuiCircularProgress-circle {
                        stroke-width: 2px;
                    }
                }
                .quiz-progress-text {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    font-size: 12px;
                    .quiz-progress-text-num {
                        font-weight: bold;
                        font-size: 23px;
                    }
                }
            }
        }
    }
    .lower-header {
        display: flex;
        margin: 20px 0;
        .left-sidebar {
            transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
            padding: 15px;
            margin-right: 25px;
            box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
            flex: 1;
            border-radius: 2px;
            margin-bottom: 0;
            &:hover {
                box-shadow: -3px 5px 10px 0 rgb(0 0 0 / 20%);
                top: 1px;
                right: 1px;
                cursor: pointer;
            }
            .sidebar-header {
                display: flex;
                .sidebar-header-left {
                    flex: 1;
                    .sub-title {
                        font-size: 11px;
                        line-height: 12px;
                        color: #676161;
                        margin-bottom: 15px;
                        text-transform: none;
                    }
                }
                .sidebar-header-right {
                    .sub-title-blue {
                        color: $blue;
                        text-align: right;
                        margin-bottom: 5px;
                    }
                }
            }
            .question-left {
                color: #473d3d;
                font-size: 14px;
                margin-left: 0;
            }
        }
        .right-sidebar {
            box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
            flex: 1;
            border-radius: 2px;
            padding: 15px;
            .dropdown {
                width: 100%;
                margin-top: 15px;
            }
            .review-btn {
                float: right;
                margin-top: 20px;
            }
        }
    }
}

@media only screen and (max-width: 890px) {
    .plan-title-ctx.ovrl-perf {
        top: 60px;
        font-size: 11px;
    }
}

@media only screen and (max-width: 740px) {
    .plan-title-ctx.ovrl-perf {
        top: 100px;
        font-size: 11px;
    }
}

@media only screen and (max-width: 600px) {
    .overall-performance-header {
        .upper-header {
            display: block;
        }
        .lower-header {
            display: block;
            .left-sidebar {
                margin: 0;
                margin-bottom: 20px;
            }
            .right-sidebar {
                overflow: auto;
            }
        }
    }
    .overall-perf .exam-container-wrapper {
        margin: 25px 0px !important;
    }
    .plan-title-ctx.ovrl-perf {
        right: 6px !important;
        top: 60px;
        font-size: 11px;
    }
}