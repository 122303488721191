$blue: #2851bf;
.overall-performance-tabs {
    .quiz-percent-cont {
        display: flex;
        .quiz-percent-progress {
            position: relative;
            height: 120px;
            margin-right: 15px;
            display: flex;
            align-items: center;
            .circular-progress-bkg {
                color: #e1e0e0;
                position: absolute;
            }
            .MuiCircularProgress-root {
                width: 120px !important;
                height: 120px !important;
                .MuiCircularProgress-circle {
                    stroke-width: 2px;
                }
            }
            .quiz-progress-text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 12px;
                .quiz-progress-text-num {
                    font-weight: bold;
                    font-size: 23px;
                }
            }
        }
    }
    .collapse-content-wrapper {
        display: flex;
        margin-top: 25px;
        .breakdown-details {
            flex: 1;
            max-width: 50%;
            margin-left: 20px;
            .item {
                display: flex;
                margin: 12px 0;
                &>p:first-child {
                    flex: 1
                }
                & p {
                    font-weight: bold;
                }
            }
        }
    }
    .collapse-btn {
        color: var(--link);
        font-weight: bold !important;
        padding: 0;
        text-transform: none;
        font-size: 14px !important;
        .MuiSvgIcon-root {
            font-size: 17px;
            color: #6b6969;
        }
    }
    .open {
        border-left: 6px solid $blue;
    }
    .closed {
        &+tr td {
            border: 0;
        }
    }
    .table-top-row {
        &:nth-child(4n+1) {
            background: #f9f9f9;
            box-shadow: inset 2px 0 5px #eee;
        }
        td {
            border: 0;
        }
    }
    .collapse-cell {
        padding-bottom: 0;
        padding-top: 0;
        border-top: 1px solid rgba(224, 224, 224, 1);
    }
    .review-tab {
        .Mui-selected {
            background: #3f51b5;
            color: #fff;
            height: 49px;
        }
    }
    .tab-container {
        padding-top: 20px;
    }
}

.pad-right {
    padding-right: 5px;
}

.no-data {
    padding: 20px;
    text-align: center;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
    top: 20px;
    width: 1px;
}

@media only screen and (max-width: 600px) {
    .overall-performance-tabs {
        margin-left: 0 !important;
        .MuiTableCell-root {
            padding: 12px 6px;
            max-width: 110px;
        }
        .MuiTableCell-head {
            font-size: 10px !important;
        }
        .collapse-btn {
            text-align: left;
        }
    }
}