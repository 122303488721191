.login-body {
    background: linear-gradient(to right, #3c5a69, #77a9c3);
    height: 100vh;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .app-name {
        margin-bottom: 30px;
        font-size: 38px;
        font-weight: bold;
        color: #ffffff;
    }
    .login-container {
        background-color: #fff;
        padding: 40px;
        border-radius: 5px;
        width: 40%;
        max-width: 450px;
        text-align: center;
        position: relative;
        .login-form {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            .MuiFormControl-root {
                margin-bottom: 10px;
                text-align: left;
            }
            .loader {
                position: absolute;
            }
            .logo {
                margin-right: 10px;
            }
            .mail-sent {
                text-align: center;
                img {
                    margin-bottom: 20px;
                }
            }
        }
        .register-btn {
            width: 100%;
            height: 50px;
            margin: 20px 0 25px;
            .MuiButton-label {
                font-size: 17px;
            }
        }
        .error-container {
            color: #df1313;
            position: absolute;
            top: 11px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        .signup-link {
            margin-bottom: 12px;
        }
    }
    .verify-text {
        margin: 15px 0;
    }
    .reg-success-alert {
        background: #636fb8;
        padding: 14px;
        border-radius: 5px;
        color: #fff;
        text-align: center;
    }
}

a {
    cursor: pointer;
}

.error {
    text-align: left;
    font-size: 14px !important;
    color: red;
}

@media only screen and (max-width: 600px) {
    .login-body .login-container {
        width: 65%;
        .signup-link {
            font-size: 13px;
        }
    }
}