.answer-container {
    display: flex;
    flex-direction: column;
}

.answer-box {
    background: var(--background-color) !important;
    color: var(--text-color) !important;
    border: 1px solid var(--blue-border) !important;
    padding: 10px !important;
    padding-right: 25px !important;
    margin-top: 20px !important;
    margin-left: 10px !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-self: baseline;
    .MuiFormControlLabel-label {
        font-size: 14px;
        text-transform: capitalize;
        color: var(--text-color) !important;
    }
    .MuiRadio-colorPrimary {
        color: var(--text-color) !important;
    }
    .eye-icon {
        position: absolute;
        right: 6px;
        bottom: 6px;
        cursor: pointer;
        &.selected {
            color: #1262db;
        }
        &.disabled {
            color: #7e7e7e;
        }
    }
}

.ans-pointer {
    cursor: pointer;
}

.container {
    background-image: var(--background-image);
    height: calc(100% - 130px);
    width: 100%;
    // margin: 60px 0px;
    padding-top: 5px;
    overflow-x: hidden;
    color: var(--text-color) !important;
    .drawer {
        position: absolute;
        top: 64px;
        bottom: 64px;
        right: 0;
        width: 42%;
        overflow-y: auto;
        overflow-x: hidden;
        transform: translateX(100%) translateX(-30px);
        -webkit-transform: translateX(100%) translateX(-30px);
        padding-left: 30px;
    }
    .drawer-close-btn {
        position: sticky;
        top: 38%;
        background: #fff;
        border: 1px solid #000;
        padding: 4px 0 2px 7px;
        cursor: pointer;
        width: 20px;
        border-right: 0;
        transform: translateX(-26px);
    }
    .selected {
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
    }
    .dismiss {
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards;
    }
    @keyframes slide-in {
        100% {
            transform: translateX(0%);
        }
    }
    @-webkit-keyframes slide-in {
        100% {
            -webkit-transform: translateX(0%);
        }
    }
    @keyframes slide-out {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(100%) translateX(-30px);
        }
    }
    @-webkit-keyframes slide-out {
        0% {
            transform: translateX(0%);
        }
        100% {
            -webkit-transform: translateX(100%) translateX(-30px);
        }
    }
}

.exam-container-wrapper {
    .pop-out-icon-outer {
        position: absolute;
        right: 40px;
        top: 70px;
        svg {
            color: var(--text-color) !important;
        }
    }
}

.question-wrapper {
    width: 40%;
    position: relative;
    .img-cont {
        max-width: 100%;
        max-height: 400px;
        margin-left: 9px;
    }
    .question-div {
        line-height: 22px;
        position: relative;
        // z-index: 11;
        &:not(.user-tutor) {
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .watermark {
            background: url(../../images/logo_light_yellow.png) no-repeat center;
            background-size: contain;
            opacity: var(--icon-opacity);
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: -1;
        }
    }
    .proceed-btn {
        margin: 20px 0px;
        align-self: flex-end;
    }
    .answer-wrapper {
        display: flex;
        align-items: center;
    }
}

.correct-icon {
    color: green;
    margin-right: 8px;
}

.incorrect-icon {
    color: red;
    margin-right: 8px;
}

.ques-id {
    font-size: 11px;
    color: #90909069;
    margin-top: 20px;
}

.ans-label {
    padding: 12px 12px 12px 0;
    font-size: 14px;
    flex: 1;
    &::first-letter {
        text-transform: uppercase;
    }
}

.exp-text {
    -webkit-user-select: none;
    user-select: none;
}

.ans-char {
    display: inline-block;
    line-height: 0px;
}

.ans-label-rad {
    margin-right: 0 !important;
}

.strike-ans {
    text-decoration-line: line-through;
}

.strike-ans-icon,
.ques-img-cont {
    cursor: pointer;
}

.strike-ans~.strike-ans-icon {
    opacity: 0.5;
}

.image-enlarge-modal {
    .MuiBackdrop-root {
        cursor: pointer;
    }
    .modal-container {
        width: fit-content;
        max-height: 87%;
        max-width: 90%;
        margin: 20px auto;
        overflow: auto;
        box-sizing: border-box;
    }
}

.zoom-btn {
    color: #ff9104 !important;
}

.zoom-btn-container {
    position: sticky;
    right: 0;
    left: 0;
}

@media only screen and (max-width: 850px) {
    // .container {
    //     margin-top: 105px;
    // }
    .question-wrapper {
        width: 57%;
        margin-bottom: 73px !important;
        margin-left: 0 !important;
        .button-container {
            display: block;
            .hide-btn-container,
            .proceed-btn {
                margin-left: 10px;
                button {
                    width: 100%;
                }
            }
            .hide-ans-btn {
                margin: 10px 0;
            }
        }
        .answer-wrapper {
            margin-bottom: 4px;
        }
    }
    .ans-char {
        display: inline-block;
        width: 21px !important;
        min-width: 21px !important;
        line-height: 0px;
        padding-left: 5px;
    }
    .ans-label {
        padding: 0;
    }
    .incorrect-icon,
    .correct-icon {
        margin-right: 0;
    }
    .quiz-page {
        .question-wrapper {
            width: 90%;
        }
    }
    .exam-container-wrapper .pop-out-icon-outer {
        right: 2px;
        top: 80px;
    }
}

@media only screen and (max-width: 600px) {
    // .container {
    //     margin-top: 79px;
    //     height: calc(100% - 118px);
    // }
    .question-wrapper {
        width: 80%;
    }
    .exam-container-wrapper .pop-out-icon-outer {
        right: 2px;
        top: 80px;
    }
    .answer-box .MuiRadio-colorPrimary {
        padding-right: 0 !important;
    }
}