.user-agreement-modal .modal-container {
    width: 550px;
    margin-top: 5%;
    border: 1px solid #dcdcdc;
    .content-box {
        max-height: calc(100vh - 300px);
        overflow: auto;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        scrollbar-width: thin;
        padding-right: 10px;
    }
    &:focus-visible {
        outline: none;
    }
    .apply-btn {
        display: flex;
        justify-content: space-between;
        button.Mui-disabled {
            border: 1px solid #dcdcdc;
        }
    }
}

.user-agreement {
    .list-header {
        font-weight: bold;
        display: list-item;
        list-style-type: disc;
        margin-left: 2em;
    }
    ul {
        padding-left: 0;
        li {
            margin-left: 40px;
            margin-top: 5px;
        }
    }
}