.exam-category-container {
    margin-top: 30px;
    border-radius: 5px;
    &>::before {
        content: null;
    }
    .MuiAccordionDetails-root {
        display: block;
    }
    .progress-container {
        display: flex;
        margin: 50px 30px;
        .progress-name {
            flex: 2;
        }
        .progress-bar {
            flex: 5;
            .MuiTypography-root {
                font-size: 12px;
            }
        }
    }
}

.MuiSelect-selectMenu,
.MuiMenuItem-root {
    text-transform: capitalize;
}

.result-loader {
    z-index: 1000 !important;
    color: #fff;
}