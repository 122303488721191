.enrollment-container {
    margin: 30px;
    .enrollment-body {
        display: flex;
        justify-content: space-between;
        table {
            max-width: 1000px;
            border: 1px solid #dcdcdc;
            .row-center {
                text-align: center;
            }
        }
         .generate-modal-btn {
            align-self: flex-start;
         }
    }
}

.invitation-modal .modal-container {
    width: auto !important;
    .email-container {
        display: flex;
        .email-label {
            color: #000;
            vertical-align: middle;
            line-height: 50px;
            margin-right: 15px;
        }
        .email-input {
            flex: 1;
        }
    }
}