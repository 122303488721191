.sub-wrapper {
    width: 100%;
    .current-sub {
        margin: 30px 0 0 30px;
        border: 1px solid #dcdcdc;
        display: inline-block;
        padding: 10px 20px;
        border-radius: 5px;
        .current-sub-wrapper {
            background: #e7e7e7ad;
            padding: 10px 20px;
            margin: 10px 0;
            border-radius: 5px;
            min-width: 400px;
        }
        .btn-wrapper {
            margin: 20px 0 10px;
            text-align: left;
            .end-sub-btn {
                background: #fefcfc;
            }
        }
        p {
            margin-top: 8px;
            text-align: left;
            span {
                float: right;
            }
        }
    }
    .plan-table {
        margin: 30px;
        border: 1px solid #dcdcdc;
        border-radius: 5px;
        .row-center {
            text-align: center;
        }
        .selected td {
            background-color: var(--active-row) !important;
        }
        .expired-plan-row td {
            background-color: var(--table-header) !important;
        }
        tr:last-child td {
            border-bottom: 0;
        }
    }
}

.plans-wrapper {
    .title {
        background-color: #1f1f86;
        text-align: center;
        padding: 5px;
        color: #fff;
    }
    .title1 {
        color: #0b8d0b;
        font-size: 15px;
        font-weight: 500;
    }
    .sub-title {
        font-size: 12px;
        display: block;
        font-weight: normal;
        font-style: italic;
    }
}

.plans-title {
    color: #0b8d0b;
    text-align: center;
    margin-top: 20px !important;
}

.confirm-btn {
    .MuiCircularProgress-indeterminate {
        position: absolute;
    }
}

.end-sub-modal {
    text-align: center;
    .end-sub-btn {
        margin-left: 20px;
    }
}

.active-actions {
    text-align: end !important;
    .end-sub-btn {
        margin-left: 10px;
    }
}

.modal-container {
    .header {
        text-align: center;
        margin-bottom: 10px;
        color: #008062;
    }
    .list-item {
        display: flex;
        border-bottom: 1px solid green;
        padding: 15px;
        align-items: center;
        padding-left: 0;
        cursor: pointer;
        .coupon-dtls {
            flex: 1;
        }
        .coupon-exp {
            margin-top: 10px;
            font-size: 13px;
        }
        .coupon-amt {
            font-size: 25px;
            font-weight: bold;
            color: green;
        }
        &.coupon-selected {
            background: #dcdcdc;
        }
    }
    .apply-btn {
        text-align: center;
        margin: 20px 0 10px;
    }
    .coupon-list {
        max-height: 300px;
        overflow: auto;
    }
}

.active-cell {
    padding-right: 5px;
    color: var(--active-green);
    vertical-align: middle;
    line-height: 35px;
    display: inline-block;
}

.cancel-date {
    color: var(--sub-text);
    font-size: 11px;
    padding-left: 5px;
}

.subscription-card {
    background: var(--background-color) !important;
    color: var(--text-color) !important;
    border: 1px solid var(--border-color);
    margin: 30px auto;
    max-width: 700px;
    text-align: center;
    .MuiTypography-root:not(.show-plan-details) {
        color: var(--text-color) !important;
    }
    .back-btn {
        float: left;
        font-size: 30px;
        cursor: pointer;
    }
    .sub-list {
        border: 1px solid #dcdcdc;
        padding: 0;
        margin: 30px 0;
        .price-text {
            color: #4caf50;
            font-weight: bold;
            &.deselected {
                color: red;
                text-decoration: line-through;
            }
        }
        .price-cont {
            display: flex;
            .new-price-text {
                margin-right: 15px;
                color: #4caf50;
                font-weight: bold;
            }
        }
        .MuiListItem-root.Mui-selected,
        .MuiListItem-root.Mui-selected:hover {
            background-color: rgb(2 99 234 / 29%);
        }
    }
    .MuiCardActions-root {
        justify-content: center;
        .MuiButton-root {
            width: 50%;
            margin-bottom: 20px;
        }
    }
    .sub-type-wrapper {
        display: flex;
        justify-content: center;
        &>.MuiBox-root {
            border: 2px solid #0b8d0b;
            padding: 20px;
            border-radius: 3px;
            margin: 20px 10px;
            text-align: left;
            cursor: pointer;
            &.selected {
                background: #74e1745e;
            }
        }
    }
}

.bold {
    font-weight: bold;
}

.card-container {
    margin: 10px auto;
    width: 85%;
}

.checkout-wrapper {
    width: 600px;
    margin: 100px auto;
    border: 1px solid #dcdcdc;
    .checkout-header {
        display: flex;
        padding: 10px;
        border-bottom: 1px solid #dcdcdc;
        p {
            font-size: 20px;
        }
        .MuiSvgIcon-root {
            cursor: pointer;
            font-size: 30px;
        }
        .checkout-header-cont {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .checkout-details {
        padding: 20px;
        text-align: center;
        .checkout-details-items {
            text-align: left;
        }
        p {
            margin-bottom: 10px;
        }
    }
}

.card-wrapper {
    border: 1px solid #dcdcdc;
    padding: 30px;
    border-radius: 4px;
}

.success-alert {
    background: #097a7a;
    color: #fff;
    padding: 14px;
    border-radius: 5px;
    font-size: 16px;
}

.bold {
    font-weight: bold !important;
}

.table-cont-wrapper {
    overflow: auto;
    .nav-btn {
        float: left;
        margin-right: 32px;
        margin-left: 32px;
        text-decoration: underline;
        cursor: pointer;
    }
    &>a {
        float: right !important;
    }
}

.payment-iframe {
    width: 100%;
}

.plan-details-wrapper {
    text-align: left;
    line-height: 25px;
    border: 1px solid #dcdcdc;
    padding: 10px;
    border-radius: 5px;
}

.plan-header {
    background: #1f1f86;
    padding: 10px;
    text-align: center;
    color: #fff;
    margin: 10px 30px !important;
}

.show-plan-details {
    color: blue !important;
    font-weight: 500;
    padding-left: 15px;
    text-decoration: underline;
    cursor: pointer;
}

.subscription-card .new-plan-header {
    color: #00cdd6 !important;
}

.plan-details-modal .close-btn {
    float: right;
    min-width: 30px;
}

@media only screen and (max-width: 600px) {
    .active-actions {
        text-align: center !important;
        .end-sub-btn {
            margin-left: 0;
        }
    }
    .sub-wrapper .plan-table {
        margin: 15px 5px;
        th,
        td {
            padding-left: 5px;
            padding-right: 5px;
            text-align: center;
        }
    }
    .checkout-wrapper {
        width: auto;
        margin: 30px 5px;
    }
}