.header-container {
    background-color: #3f51b5 !important;
    color: #fff;
    height: 64px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    padding-left: 10px;
    .MuiGrid-item,
    .MuiFormControlLabel-root {
        background-color: #3f51b5 !important;
        color: #fff !important;
    }
    .MuiButton-outlinedPrimary {
        color: #3f51b5 !important;
        border: 1px solid rgba(63, 81, 181, 0.5) !important;
    }
}

.nextBtn {
    color: #fff !important;
    font-size: 14px;
    .MuiIconButton-label {
        flex-direction: column;
    }
}

.fontContainer {
    background-color: #fff;
    height: 25px;
    .fontBtn {
        min-width: 20px;
        font-weight: 500 !important;
    }
    .smallFont {
        font-size: 12px !important;
    }
    .mediumFont {
        font-size: 14px !important;
    }
    .largeFont {
        font-size: 17px !important;
    }
}

.active-font-btn {
    background: #cddbea !important;
}

button.MuiIconButton-root.Mui-disabled {
    color: #ffffff9e !important;
}

div:not(.feedback-body) > .form-row {
    margin-bottom: 15px;
    .MuiFormLabel-root,
    .MuiRadio-root:not(.Mui-checked),
    .MuiInputBase-input {
        color: var(--label-color) !important;
    }
}

.cmt-cont {
    display: flex;
    flex-direction: column;
    .box {
        display: block;
        margin: 5px 0;
        font-size: 15px;
        line-height: 19px;
    }
    .radio-grp {
        display: flex;
        align-items: center;
    }
    .bold {
        font-weight: bold;
        display: block;
    }
    button.MuiIconButton-root.Mui-disabled {
        color: rgba(0, 0, 0, 0.26) !important;
    }
    .cmt-header {
        background: #4f5fba;
        line-height: 45px;
        color: #fff;
        text-align: center;
    }
    .cmt-body {
        height: 400px;
        overflow: auto;
        .cmt-item {
            border-bottom: 1px solid #dcdcdc;
            padding: 7px;
            font-size: 14px;
            .cmt-user-text {
                vertical-align: top;
                line-height: 25px;
                padding-left: 5px;
                max-width: 146px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .cmt-date {
                font-size: 10px;
                float: right;
                color: #686868;
                vertical-align: middle;
                line-height: 23px;
            }
            .cmt-text {
                padding: 5px 0;
                font-size: 16px;
                width: 87%;
                word-break: break-all;
            }
            .user-icon {
                border: 1px solid #b7b7b7;
                border-radius: 50%;
            }
            .cmt-reply {
                display: flex;
                justify-content: space-between;
                .MuiIconButton-root {
                    padding: 0 5px;
                }
            }
            &.reply {
                .cmt-date {
                    float: left;
                }
                .cmt-text {
                    text-align: right;
                    width: 97%;
                }
                .cmt-user-text-wrap {
                    float: right;
                }
                .cmt-user {
                    overflow: auto;
                }
                .cmt-user-text {
                    margin-right: 8px;
                }
            }
        }
        .green {
            color: #238406;
        }
        .blue {
            color: #3f51b5;
        }
    }
    .cmt-footer {
        display: flex;
        .MuiFormControl-root {
            margin: 5px;
            flex: 1;
        }
    }
    .MuiFormLabel-root {
        color: #000 !important;
    }
}

.marg-rt {
    margin-right: 5px !important;
}

.marg-top {
    margin-top: 13px !important;
}

.cmt-impt {
    margin-bottom: 5px !important;
}

.ques-feedbk-modal {
    width: 55% !important;
    max-height: 85% !important;
    overflow: auto;
    &.tutor-mode {
        width: 70% !important;
    }
    .feedback-body {
        padding: 15px 20px;
        .btn-row {
            margin-top: 30px;
            display: flex;
            justify-content: center;
            .MuiButton-root {
                width: 30%;
                margin: 0 10px;
            }
        }
        .MuiFormControlLabel-label {
            font-size: 15px;
        }
        .MuiRadio-root {
            padding-right: 4px;
        }
    }
    .line-border {
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #dcdcdc;
    }
    .comment-label {
        line-height: 25px;
        font-size: 14px;
    }
}

.wd-100 {
    width: 100%;
}

.nm {
    margin: 0 !important;
}

.med-logo1 {
    display: none;
}

.go-to-home {
    cursor: pointer;
}

.success-warn {
    background: #cf9325;
    color: #fff;
    padding: 14px;
    border-radius: 5px;
    font-size: 16px;
}

.tutor-feedback {
    margin: 20px;
    .MuiFormLabel-root {
        font-size: 14px;
    }
    .comment-wrapper .MuiFormLabel-root {
        font-size: 13px;
    }
    .MuiTypography-body1 {
        font-size: 13px;
    }
    .radio-grp .MuiFormLabel-root {
        width: 70px;
        min-width: 70px;
    }
    .MuiRadio-root {
        padding-right: 3px;
    }
    .btn-row {
        text-align: center;
        margin-top: 20px;
    }
}

.next-btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 760px) {
    .font-btn-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .header-container {
        height: auto;
        .blockContainer {
            font-size: 11px !important;
            line-height: 20px;
        }
        .mark-cont {
            .MuiSvgIcon-root,
            .MuiFormControlLabel-label {
                font-size: 14px;
            }
        }
    }
    .med-logo {
        display: none;
    }
    .med-logo1,
    .info-list-items .item-row {
        display: block !important;
    }
    .mark-cont>label {
        margin-left: -8px;
    }
    .ques-feedbk-modal {
        min-width: 390px !important;
        &.tutor-mode {
            width: 90% !important;
        }
    }
    .feedback p,
    .label1 {
        font-size: 12px !important;
    }
    .label1 {
        word-break: break-word;
    }
    .feedback.info-list-items .item-value {
        margin-left: 0 !important;
    }
    .ques-feedbk-modal .feedback-body .MuiFormControlLabel-label {
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 600px) {
    .font-btn-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .header-container {
        padding-left: 5px;
    }
    .font-btn-wrap {
        font-size: 12px;
        button {
            padding-right: 5px !important;
        }
    }
    .next-btn-wrap {
        button {
            padding-bottom: 2px !important;
            padding-top: 2px !important;
            padding-left: 0 !important;
        }
    }
    .mark-cont>label {
        margin-left: -5px;
        margin-top: 3px;
    }
    .ques-feedbk-modal {
        min-width: 330px !important;
    }
}