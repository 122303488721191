.feedback-dashboard {
    .dashboard {
        flex: 1;
        margin: 25px;
        .dropdown {
            width: 200px;
        }
    }
    .ques-id-row {
        margin-bottom: 5px;
    }
    .list-wrapper {
        // display: flex;
        // align-items: flex-start;
        position: relative;
        height: 88%;
        width: 80%;
        margin-top: 20px;
        .question-list {
            position: absolute;
            left: 0;
            top: 0px;
            bottom: -17px;
            overflow: auto;
            float: left;
            direction: rtl;
            li {
                cursor: pointer;
            }
        }
        .feedback-list {
            margin: 15px 0;
            border: 1px solid #dcdcdc;
            padding: 20px;
            max-width: 80%;

            .marg-10 {
                margin: 7px 0;
            }

            &.tutor {
                flex: 1;
                padding: 0;

                .info-list-items {
                    padding: 15px;
                }
            }

            .feedback-label {
                color: #0419f8;
                font-size: 19px;
            }
            .info-list-items:last-child {
                border: none;
            }

            .text-ellipsis-multiline {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: 13px;
                margin: 7px 0;
            }
        }
    }
}