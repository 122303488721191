.quiz-details {
    display: flex;
    padding: 20px;
    .quiz-details-content {
        flex: 1;
        .quiz-details-content-header {
            font-size: 17px;
            font-weight: bold;
            color: var(--link);
            margin-bottom: 6px;
        }
    }
    .quiz-details-btn-cont {
        font-weight: bold;
    }
}

.quiz-text {
    font-size: 14px;
    line-height: 20px;
}

.quiz-result-numbers {
    display: flex;
    padding: 20px;
    font-size: 14px;
    .quiz-percent-cont {
        display: flex;
        align-items: center;
        flex: 1;
        .quiz-percent-progress {
            position: relative;
            height: 90px;
            margin-right: 10px;
            .circular-progress-bkg {
                color: #e1e0e0;
                position: absolute;
            }
            .MuiCircularProgress-root {
                width: 90px !important;
                height: 90px !important;
                .MuiCircularProgress-circle {
                    stroke-width: 2px;
                }
            }
            .quiz-progress-text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 12px;
                .quiz-progress-text-num {
                    font-weight: bold;
                    font-size: 23px;
                }
            }
        }
    }
    .quiz-percent-total {
        border-left: 1px solid #a8a7a7;
        margin-left: 10px;
        padding-left: 20px;
        flex: 1;
    }
    .quiz-progress-text-bold {
        font-weight: bold;
        padding-right: 4px;
        line-height: 25px;
    }
    .quiz-ans-changes {
        display: flex;
        margin-top: 10px;
        justify-content: center;
        .quiz-ans-changes-container {
            text-align: center;
            margin: 0 5px;
            .quiz-ans-changes-icons {
                display: flex;
            }
        }
    }
}

.icon {
    font-size: 18px !important;
    &.step-icon {
        margin-bottom: -4px;
    }
    &.correct-icon {
        color: #239e23;
    }
    &.incorrect-icon {
        color: #f14c4c;
    }
}

.text-center {
    text-align: center;
}

.MuiOutlinedInput-notchedOutline {
    border-color: var(--border-color) !important;
}


@media only screen and (max-width: 600px) {
    .quiz-result-numbers {
        display: block;
        padding-bottom: 0;

        .quiz-percent-total {
            border: none;
            margin: 10px 0;
            padding: 0;
        }
    }
    .quiz-details .quiz-details-content {
        flex: auto
    }
    .exam-category-container .progress-container {
        margin: 25px 0px !important;
        .progress-name {
            flex: 6 1;
        }
    }
}