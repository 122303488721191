html,
body,
#root,
.body-cont {
    height: 100%;
}

.body-cont,
.body-cont-over,
.quiz-history-table-container,
.app-body-left-container,
.app-body-right-container,
.MuiGrid-item {
    background: var(--background-color) !important;
    color: var(--text-color) !important;
    th,
    td,
    select,
    .MuiAccordion-root,
    .MuiInputBase-root,
    label,
    .exam-result>.MuiPaper-root {
        background: var(--background-color) !important;
        color: var(--text-color) !important;
    }
    .MuiNativeSelect-icon {
        color: var(--text-color) !important;
    }
    select option {
        background: var(--background-color) !important;
        color: var(--text-color) !important;
    }
    // table,
    .MuiPaper-root {
        border: 1px solid var(--border-color);
    }
}

.exam-result {
    margin: 20px;
    flex: 1;
}

.body-cont,
.body-cont-over {
    display: flex;
    min-height: 100vh;
}

.plan-title-ctx {
    position: absolute;
    right: 30px;
    top: 23px;
    background: #b1f5a6;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    color: #128aef;
    cursor: pointer;
    &.expired {
        background: #e7616191;
        color: var(--label-color) !important;
    }
    &.ovrl-perf {
        right: 340px;
    }
    &.crt-qz {
        position: static;
        cursor: pointer;
    }
}

@media only screen and (max-width: 600px) {
    .exam-result {
        width: 80%;
        margin: 20px 10px;
    }
}