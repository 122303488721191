.review-tab {
    border-bottom: 1px solid #969696;
}

.info-list-items {
    display: flex;
    border-bottom: 2px solid #d7d7d7;
    padding: 10px 0;
    &:first-child {
        padding-top: 0;
    }
    &.feedback {
        display: block;
        .item-value {
            text-align: left;
            margin-right: 8px;
            margin-top: 10px;
            font-size: 14px;
            label {
                font-weight: 500;
                margin-right: 4px;
            }
        }
    }
    .label {
        flex: 1;
    }
    .item-value {
        font-weight: bold;
        max-width: 200px;
        text-align: right;
    }
    .item-row {
        display: flex;
    }
    .label-box {
        margin-top: 8px;
        font-weight: bold;
    }
    .item-value-box {
        font-size: 14px;
        margin-top: 4px;
        span {
            display: inline-block;
            margin-right: 2px;
            font-weight: 500;
            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }
}

.MuiTab-textColorPrimary.Mui-selected {
    font-weight: bold !important;
}

.resource-list {
    display: flex;
    flex-direction: column;
    a {
        margin-bottom: 30px;
    }
}

.hide {
    display: none;
}

.close-btn {
    float: right;
    z-index: 1000;
    position: sticky !important;
    top: 0;
}

.no-feedback-cont {
    display: flex;
    justify-content: space-between;
    place-items: center;
}

.quesfeedback-btn {
    color: #3f51b5 !important;
    font-size: 14;
    .MuiIconButton-label {
        flex-direction: column;
    }
}

.ans-item {
    .correct-ans-lbl {
        font-weight: bold;
        display: inline-block;
        &::first-letter {
            text-transform: uppercase;
        }
    }
}

.exp-header {
    display: flex;
    justify-content: space-between;
    &>h2 {
        margin-left: 10px;
    }
}

.drawer-cont {
    border-left: 1px solid;
    background: var(--background-color) !important;
    min-height: 100%;
    margin-top: -36px;
}

.drawer {
    &.selected .drawer-close-btn svg {
        rotate: 180deg;
        margin-left: -10px;
    }
    .drawer-close-btn svg {
        color: #505050 !important;
    }
    .pop-out-icon svg {
        color: var(--text-color) !important;
    }
}

.additional-exp {
    border-top: 1px solid #dcdcdc;
    margin-top: 30px;
}

.header-lbls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 0 20px;
    .icons-wrap {
        display: flex;
        align-items: center;
        font-weight: bold;
        svg {
            margin-right: 2px;
        }
    }
}

.correct-ans {
    background: var(--correct-ans-color);
}

.wrong-ans {
    background: var(--wrong-ans-color);
}

.brief-exp {
    background-color: var(--exp-bkg-color);
    color: var(--text-color);
    padding-bottom: 5px;
    padding-right: 5px;
    ul {
        padding-left: 22px;
    }
}

.header-lbls {
    color: #000;
}

.quide-review-cont {
    margin-top: 30px;
}

@media only screen and (max-width: 800px) {
    .drawer {
        top: 102px !important;
    }
}

@media only screen and (max-width: 600px) {
    .tabs-cont {
        padding: 10px !important;
    }
    .drawer {
        width: 80% !important;
        z-index: 12;
        .MuiTabs-flexContainer {
            button {
                padding-left: 5px;
                .MuiTab-wrapper {
                    align-items: flex-start;
                }
            }
        }
        .info-list-items {
            overflow: auto;
            p {
                font-size: 12px;
            }
            .item-value {
                margin-left: 5px;
            }
        }
    }
    .MuiTab-root {
        min-height: 35px !important;
    }
    .exp-text,
    .ans-item {
        font-size: 13px !important;
    }
    .header-lbls {
        margin: 0 10px;
    }
}