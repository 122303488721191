.exam-table-container {
    margin-top: 30px;
    margin-bottom: 30px;
    .action-icon {
        color: var(--action-icon);
    }
    .text-ellipsis-multiline {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 13px;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .review-button {
        border: 2px solid #005de8;
        border-radius: 20px;
        .MuiButton-label {
            font-weight: bold;
            color: #005de8;
        }
        &.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
            border-color: rgba(0, 0, 0, 0.26);
            .MuiButton-label {
                color: rgba(0, 0, 0, 0.26);
            }
        }
    }
    .reviewed-button {
        color: #005de8;
        font-weight: bold !important;
        .MuiSvgIcon-root {
            color: #005de8;
            font-size: 19px;
            margin-right: 4px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .mobile-view-hide {
        display: none !important;
    }
    .exam-table-container {
        .MuiTableCell-root {
            padding: 12px 8px;
        }
        .review-btn-cont {
            text-align: right;
        }
    }
}