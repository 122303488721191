$height: 20px;
$badgeColor: #f37c32;
.MuiButton-outlined:not(.google-btn) {
    border: 1px solid var(--border-color) !important;
    color: var(--text-color) !important;
}

.MuiButton-contained.Mui-disabled:not(.register-btn) {
    color: var(--border-color) !important;
    background: var(--list-bkg) !important;
}

.quiz-container .MuiFormControlLabel-label.Mui-disabled,
.quiz-container .MuiCheckbox-colorPrimary.Mui-disabled {
    color: var(--border-color) !important;
}

.app-body {
    margin-top: 5%;
    flex-grow: 1;
    &-left-container,
    &-right-container {
        padding: 30px;
        flex-grow: 1;
        overflow: auto;
        border-radius: 0 !important;
    }
}

.app-body-right-container {
    position: relative;
    border: 0 !important;
    overflow: hidden;
}

.app-body-left-container {
    border: 0 !important;
}

.quiz-container {
    .MuiAccordionSummary-root.Mui-expanded {
        border-bottom: 1px solid var(--border-color) !important;
        min-height: 48px;
    }
    .MuiAccordionSummary-content {
        margin: 0 !important;
        align-items: center;
    }
    .MuiAccordion-root.Mui-expanded {
        border: 1px solid var(--border-color);
    }
    .lower-container {
        margin-top: 40px;
    }
}

.quiz-selections {
    border: 1px solid var(--border-color);
    box-shadow: none !important;
}

.accordian-summary {
    flex-wrap: wrap;
    .category-chip {
        margin: 0 3px 5px;
    }
    .no-select {
        color: #e01919;
        font-size: 14px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.empty-text input {
    background-color: #dacf65;
}

.quiz-header {
    margin-top: 0px;
    padding-top: 10px;
}

.user-container {
    .user-welcome {
        color: #128aef;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .quiz-limit {
            float: right;
            color: #006900;
        }
        .user-role {
            color: #ff0c0c;
            margin-left: 16px;
            background: #ffff42;
            padding: 1px 5px;
            border-radius: 2px;
            /* text-transform: uppercase; */
            font-size: 12px;
            font-weight: bold;
        }
    }
    .user-resume {
        margin-top: 20px;
        display: flex;
        .user-resume-text {
            border: 1px solid gainsboro;
            padding: 10px;
            border-radius: 3px;
            margin-right: 15px;
        }
        .resume-btn {
            background-color: #29ac21;
            color: #fff;
            font-size: 18px !important;
            text-transform: capitalize;
        }
    }
}

.sub-alert {
    background: #d43636;
    padding: 14px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
}

.warn-alert {
    background: #dacf65;
    padding: 14px;
    border-radius: 5px;
}

.success-alert {
    background: #097a7a;
    color: #fff;
    padding: 14px;
    border-radius: 5px;
}

.welcome-alert {
    background: $badgeColor;
    color: #fff;
    padding: 14px;
    border-radius: 5px;
    display: flex;
}

.is-disabled {
    pointer-events: none;
    opacity: 0.9;
}

.feedback-btn {
    position: fixed !important;
    right: 50px;
    bottom: 120px;
    z-index: 100000;
    background-color: #ffff !important;
    border-radius: 50% !important;
    border: 1px solid !important;
    box-shadow: 1px 9px 12px;
}

.feedback-textarea {
    width: 100%;
}

.badge {
    position: absolute;
    right: 0;
    top: 14px;
    background-color: $badgeColor;
    color: #fff;
    padding: 0 0.5em;
    line-height: 20px;
    rotate: 45deg;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    &:after {
        content: ' ';
        position: absolute;
        right: -$height;
        top: 0px;
        border-right: $height solid transparent;
        border-bottom: $height solid $badgeColor;
    }
    &:before {
        content: ' ';
        position: absolute;
        left: -$height;
        top: 0;
        border-left: $height solid transparent;
        border-bottom: $height solid $badgeColor;
    }
}

.categories .MuiAccordionSummary-root {
    justify-content: flex-start;
}

.category-column {
    border-right: 1px solid #cccccc;
}

.modal-container {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 30%;
    margin: 15% auto;
    text-align: center;
    .end-sub-btn {
        margin-left: 20px;
    }
}

.ques-cnt-txt {
    color: #0032ff;
    font-weight: bold;
    &.zero {
        color: #fa0000;
    }
}

.ques-lbl {
    font-size: 13px;
}

.info-tooltip {
    margin: 0 0 -3px 3px;
    font-size: 16px !important;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .sub-end-modal .modal-container {
        width: 60%;
    }
}

@media only screen and (max-width: 600px) {
    .app-body-left-container,
    .app-body-right-container {
        padding: 5px;
    }
    .MuiFormControlLabel-label {
        font-size: 12px !important;
    }
    .PrivateSwitchBase-root-20 {
        padding: 6px 2px !important;
    }
    .categories {
        .MuiAccordionDetails-root {
            padding-left: 11px;
        }
        .cat-heading {
            font-size: 12px;
        }
        .MuiIconButton-root {
            padding: 6px 0px;
        }
        .MuiSvgIcon-root {
            font-size: 18px;
        }
    }
    .user-container .user-welcome {
        font-size: 14px;
        line-height: 25px;
        width: 80%;
        flex-direction: column;
        align-items: flex-start;
    }
    .plan-title-ctx.crt-qz {
        font-size: 10px;
        padding: 0 5px;
    }
    .header {
        flex-basis: 72% !important;
    }
    .feedback-btn {
        display: none !important;
        right: 8px;
    }
    .MuiCollapse-wrapperInner {
        margin: 5px 0;
    }
    .lower-container .MuiGrid-item {
        margin-bottom: 15px;
    }
    .user-resume,
    .user-container .user-resume .resume-btn {
        font-size: 13px !important;
    }
    .modal-cont {
        min-width: 360px !important;
    }
    .categories-summary {
        padding-left: 0 !important;
    }
    .MuiChip-deletable {
        font-size: 12px;
    }
    .accordian-summary .MuiCheckbox-root {
        padding: 4px;
    }
    .user-agreement-modal .modal-container {
        width: 80%;
        .MuiFormControlLabel-label {
            text-align: left;
        }
    }
    .sub-end-modal .modal-container {
        width: 60%;
    }
}