.quiz-history-table-container {
    flex: 1;
    margin: 25px !important;
    .sub-title {
        margin-bottom: 15px;
    }
    .quiz-percent-progress {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .circular-progress-bkg {
            color: #e1e0e0;
            position: absolute;
        }
        .quiz-progress-text {
            position: absolute;
            font-size: 18px;
        }
    }
    .timer-wrapper {
        display: flex;
        font-size: 13px;
        margin-top: 8px;
        &>span {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 10px;
            svg {
                font-size: 15px;
                margin-right: 5px;
            }
        }
        .blue-text {
            color: #007cfc;
        }
    }
    .cell-width {
        max-width: 250px;
    }
}

.MuiMenu-paper {
    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
        background-color: #a1c2e4 !important;
    }
    .MuiListItem-button:hover {
        background-color: #ddeeff !important;
    }
}

.quiz-loader {
    z-index: 1000 !important;
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .hide-in-mobile {
        display: none !important;
    }
    .quiz-history-table-container table td {
        padding-left: 2px;
        padding-right: 2px;
    }
    .total-ques {
        text-align: center !important;
    }
}