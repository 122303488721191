.categories {
    &-summary {
        border-bottom: 1px solid #e6e6e6 !important;
        padding-left: 5px !important;
    }
    .MuiAccordionDetails-root {
        padding: 0 15px;
    }
    .MuiAccordionSummary-content {
        align-items: center;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 0;
    }
    .cat-heading {
        color: #0058ff;
        font-weight: bold;
    }
}

.MuiAccordionSummary-expandIcon .MuiSvgIcon-root,
.MuiSelect-icon {
    color: var(--text-color) !important;
}

.MuiCheckbox-colorPrimary:not(.Mui-checked)  .MuiSvgIcon-root {
    color: var(--border-color) !important;
}